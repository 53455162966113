  .carousel {
    margin-bottom: 4rem;
  }
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }
  
  .carousel-item {
    height: 32rem;
  }
  
  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  
  .featurette-divider {
    margin: 5rem 0; 
  }
  
  .featurette-heading {
    letter-spacing: -.05rem;
  }
  
  @media (min-width: 40em) {
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  
    .featurette-heading {
      font-size: 50px;
    }
  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }
  .imga{
    background-image: 'new_image.jpg';
  }