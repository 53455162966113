.width_setter{
  width: 250px;
}


.green{
  margin: 20px;
}
.shadowish{
  box-shadow: 2px 3px;
  height: 100vh;
  width: 100vw;
}
.padded{
  padding-left: 65px;
  padding-right: 65px;
  padding-top: 30px;
}
@media screen and (max-width: 800px) {
  .padded{
    padding: 50px;
  }  
}

@media screen and (max-width:500px) {
  .searchbox {
    width: 80%;
  }
  .padded{
    padding: 20px;
  }
}
@media screen and (min-width:500px){
.searchbox{
  width: 500px;
}
}