.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .date{
    font-size: 20px;
  }
  .deadline{
    float: right;
  }
  .submit-button{
    float:right;
    margin: 10px;
  }
  .whole{
    margin:10px;
  }
  .simple-bar{
    margin: 8px;
    width: 230px;
    border:2px solid rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    height: min-content;
  }
  .simple-bar h6{
    align-self: center;
    background-color: lightslategray;
    border-bottom: 5px solid darkgray;
    color: white;
    width: 100%;
    padding: 8px 0;
    margin:0;
  }
  .main{
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width:800px) {
    .main{
      display: block;
    }
        #no-more-tables tbody,
        #no-more-tables tr,
        #no-more-tables td {
            display: block;
        }
        #no-more-tables thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        #no-more-tables td {
            position: relative;
            padding-left: 50%;
            border: none;
            border-bottom: 1px solid #eee;
        }
        #no-more-tables td:before {
            content: attr(data-title);
            position: absolute;
            left: 6px;
            font-weight: bold;
        }
        #no-more-tables tr {
            border-bottom: 3px double #ccc;
        }
        .remark{
          width : 140px;
        }
      }
