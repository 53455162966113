// * {
//   padding: 0;
//   margin: 0;
//   color: #1a1f36;
//   box-sizing: border-box;
//   word-wrap: break-word;
//   font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
//     Helvetica Neue, Ubuntu, sans-serif;
// }
.h1 {
  letter-spacing: -1px;
}
.a {
  color: #9f5d07;
  text-decoration: unset;
}
.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.loginbackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}
.flex-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.center-center {
  align-items: center;
  justify-content: center;
}
.box-root {
  box-sizing: border-box;
}
.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.loginbackground-gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [left-gutter] 1fr [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}
.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}
.box-background--blue {
  background-color: #e77b5d;
}
.box-background--white {
  background-color: #ffffff;
}
.box-background--blue800 {
  background-color: #db770b;
}
.box-background--gray100 {
  background-color: #e7c25d;
}
.box-background--cyan200 {
  background-color: #9f5307;
}
.padding-top--64 {
  padding-top: 64px;
}
.padding-top--24 {
  padding-top: 24px;
}
.padding-top--48 {
  padding-top: 48px;
}
.padding-bottom--24 {
  padding-bottom: 24px;
}
.padding-horizontal--48 {
  padding: 48px;
}
.padding-bottom--15 {
  padding-bottom: 15px;
}

.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  // margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
.span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}
.label {
  margin-bottom: 10px;
}
.reset-pass a,
.label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.reset-pass > a {
  text-align: right;
  margin-bottom: 10px;
}
.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.field input {
  font-size: 13px;
  line-height: 28px;
  padding: 5px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.input[type="submit"] {
  background-color: rgb(7, 105, 159);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(7, 105, 159) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.field-checkbox input {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  box-shadow: unset;
  min-height: unset;
}
.field-checkbox label {
  display: flex;
  align-items: center;
  margin: 0;
}
a.ssolink {
  display: block;
  text-align: center;
  font-weight: 600;
}
.footer-link span {
  font-size: 14px;
  text-align: center;
}
.listing a {
  color: #b9bdbf;
  font-weight: 600;
  margin: 0 10px;
}

.animationRightLeft {
  animation: animationRightLeft 30s ease-in-out infinite;
}
.animationLeftRight {
  animation: animationLeftRight 30s ease-in-out infinite;
}
.tans3s {
  animation: animationLeftRight 30s ease-in-out infinite;
}
.tans4s {
  animation: animationLeftRight 30s ease-in-out infinite;
}

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}
.my_custom{
  // min-width: 100%;
  z-index: 1;
}

.first1{
  grid-area: top / start / 8 / end;
}

.first2{
  background-image: linear-gradient(white 0%, rgb(247, 250, 252) 33%); flex-grow: 1;
}
.first3{
  grid-area: 4 / 2 / auto / 5;
}
.first4{
  flex-grow: 1;
}
.first5{
  grid-area: 6 / start / auto / 2;
}
.first6{
  flex-grow: 1;
}
.first7{
  grid-area: 7 / start / auto / 4;
}
.first8{
  flex-grow: 1;
}


.first9{
  grid-area: 8 / 4 / auto / 6;
}

.first10{
  flex-grow: 1;
}
.first11{
  grid-area: 2 / 15 / auto / end;
}
.first12{
  flex-grow: 1;
}
.first13{
  grid-area: 3 / 14 / auto / end;
}
.first14{
  flex-grow: 1;
}
.first15{
  grid-area: 4 / 17 / auto / 20;
}
.first16{
  flex-grow: 1;
}
.first17{
  grid-area: 5 / 14 / auto / 17;
}
.first18{
  flex-grow: 1;
}
.first19{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9;
}
.formbg-outer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alla{
  display: none;
}
// .alla{
//   min-height: 100vh;
//   flex-grow: 1;
// }
@media screen and (max-width:600px){
  .loginbackground{
    display: none;
  }
  .h1{
    font-size: 38;
    font-weight:lighter;
  }
  .first19{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .formbg-outer{
    width: 90%;
    max-width: 448px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .padding-horizontal--48{
    padding: 48px;
  }
  .alla{
    display: none;
  }
}
@media screen and (max-width:400px){
  .padding-horizontal--48{
    padding: 28px;
  }
}